<template>
  <div class="flex flex-col gap-4 w-full">
    <div class="flex flex-col px-3 py-5 gap-8">
      <div class="flex justify-start items-center gap-8">
        <div class="flex justify-start items-center gap-0">
          <back-button
            @onClick="$router.back()"
            variant="secondary"
            style="padding: 0"
          />
          <h1 class="text-xl text-left font-extrabold">Time Off</h1>
        </div>
        <breadcrumb :items="breadcrumbs" />
      </div>
      <c-card class="flex flex-col gap-6 py-3 px-3 w-full">
        <div class="flex flex-col gap-4 w-full">
          <c-card
            class="flex justify-start items-center gap-6 py-6 px-2"
            style="background-color: rgba(238, 238, 238, 0.24)"
          >
            <div class="w-full flex gap-4 px-3">
              <div class="flex w-full gap-3">
                <div
                  class="flex justify-center items-center p-2 rounded-md"
                  style="background-color: rgba(33, 118, 255, 0.1)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.794 16.7996L1.20039 0.205994C0.925735 -0.0686646 0.480515 -0.0686646 0.205994 0.205994C-0.0686646 0.480515 -0.0686646 0.925735 0.205994 1.20026L3.76694 4.76134C3.6237 5.21727 3.55078 5.70795 3.55078 6.22266V14.0977H2.63672C2.24835 14.0977 1.93359 14.4124 1.93359 14.8008C1.93359 15.1891 2.24835 15.5039 2.63672 15.5039H7.1852C7.13095 15.682 7.10156 15.8707 7.10156 16.0664C7.10156 17.1326 7.96893 18 9.03516 18C10.1014 18 10.9688 17.1326 10.9688 16.0664C10.9688 15.8707 10.9392 15.682 10.885 15.5039H14.5095L16.7996 17.794C16.9369 17.9313 17.117 18 17.2969 18C17.4768 18 17.6567 17.9313 17.794 17.794C18.0687 17.5195 18.0687 17.0743 17.794 16.7996ZM9.03516 16.5938C8.74429 16.5938 8.50781 16.3571 8.50781 16.0664C8.50781 15.7755 8.74429 15.5391 9.03516 15.5391C9.32588 15.5391 9.5625 15.7755 9.5625 16.0664C9.5625 16.3571 9.32588 16.5938 9.03516 16.5938ZM10.3611 11.3555H4.95703V6.22266C4.95703 6.13394 4.96046 6.04674 4.96623 5.96063L10.3611 11.3555ZM4.95703 14.0977V12.7617H11.7673L13.1033 14.0977H4.95703ZM5.99826 2.91824C5.84528 2.56133 6.01062 2.14796 6.36768 1.99498C6.96588 1.73859 7.62465 1.57681 8.33203 1.51076V0.703125C8.33203 0.314758 8.64679 0 9.03516 0C9.42352 0 9.73828 0.314758 9.73828 0.703125V1.51021C12.6238 1.77745 14.5195 3.61285 14.5195 6.22266V10.0898C14.5195 10.4782 14.2048 10.793 13.8164 10.793C13.428 10.793 13.1133 10.4782 13.1133 10.0898V6.22266C13.1133 3.80223 11.0385 2.91069 9.09503 2.88322H8.97528C8.22409 2.89394 7.5141 3.03374 6.92139 3.28752C6.56447 3.44051 6.15111 3.27516 5.99826 2.91824Z"
                      fill="#2176FF"
                    />
                  </svg>
                </div>
                <div class="flex flex-col w-full">
                  <span
                    class="text-xs font-semibold text-blueCrayola uppercase text-jet"
                    >Total Requested</span
                  >
                  <span class="text-lg font-extrabold">{{
                    pendingRequests.length
                  }}</span>
                </div>
              </div>
              <div
                class="h-[50px] w-[1px] flex flex-grow flex-col border border-dashed"
              />
              <div class="flex w-full gap-3">
                <c-card
                  class="flex justify-center items-center p-2"
                  style="background-color: rgba(233, 147, 35, 0.1)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.794 16.7996L1.20039 0.205994C0.925735 -0.0686646 0.480515 -0.0686646 0.205994 0.205994C-0.0686646 0.480515 -0.0686646 0.925735 0.205994 1.20026L3.76694 4.76134C3.6237 5.21727 3.55078 5.70795 3.55078 6.22266V14.0977H2.63672C2.24835 14.0977 1.93359 14.4124 1.93359 14.8008C1.93359 15.1891 2.24835 15.5039 2.63672 15.5039H7.1852C7.13095 15.682 7.10156 15.8707 7.10156 16.0664C7.10156 17.1326 7.96893 18 9.03516 18C10.1014 18 10.9688 17.1326 10.9688 16.0664C10.9688 15.8707 10.9392 15.682 10.885 15.5039H14.5095L16.7996 17.794C16.9369 17.9313 17.117 18 17.2969 18C17.4768 18 17.6567 17.9313 17.794 17.794C18.0687 17.5195 18.0687 17.0743 17.794 16.7996ZM9.03516 16.5938C8.74429 16.5938 8.50781 16.3571 8.50781 16.0664C8.50781 15.7755 8.74429 15.5391 9.03516 15.5391C9.32588 15.5391 9.5625 15.7755 9.5625 16.0664C9.5625 16.3571 9.32588 16.5938 9.03516 16.5938ZM10.3611 11.3555H4.95703V6.22266C4.95703 6.13394 4.96046 6.04674 4.96623 5.96063L10.3611 11.3555ZM4.95703 14.0977V12.7617H11.7673L13.1033 14.0977H4.95703ZM5.99826 2.91824C5.84528 2.56133 6.01062 2.14796 6.36768 1.99498C6.96588 1.73859 7.62465 1.57681 8.33203 1.51076V0.703125C8.33203 0.314758 8.64679 0 9.03516 0C9.42352 0 9.73828 0.314758 9.73828 0.703125V1.51021C12.6238 1.77745 14.5195 3.61285 14.5195 6.22266V10.0898C14.5195 10.4782 14.2048 10.793 13.8164 10.793C13.428 10.793 13.1133 10.4782 13.1133 10.0898V6.22266C13.1133 3.80223 11.0385 2.91069 9.09503 2.88322H8.97528C8.22409 2.89394 7.5141 3.03374 6.92139 3.28752C6.56447 3.44051 6.15111 3.27516 5.99826 2.91824Z"
                      fill="rgba(233, 147, 35, 1)"
                    />
                  </svg>
                </c-card>
                <div class="flex flex-col pl-4 w-full">
                  <span
                    class="text-xs font-semibold text-carrotOrange uppercase text-jet"
                    >Total Approved</span
                  >
                  <span class="text-lg font-extrabold">{{
                    approvedRequests.length
                  }}</span>
                </div>
              </div>
              <div
                class="h-[50px] w-[1px] flex flex-grow flex-col border border-dashed"
              />
              <div class="flex w-full gap-3">
                <c-card
                  class="flex justify-center items-center p-2"
                  style="background-color: rgba(19, 181, 106, 0.1)"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.794 16.7996L1.20039 0.205994C0.925735 -0.0686646 0.480515 -0.0686646 0.205994 0.205994C-0.0686646 0.480515 -0.0686646 0.925735 0.205994 1.20026L3.76694 4.76134C3.6237 5.21727 3.55078 5.70795 3.55078 6.22266V14.0977H2.63672C2.24835 14.0977 1.93359 14.4124 1.93359 14.8008C1.93359 15.1891 2.24835 15.5039 2.63672 15.5039H7.1852C7.13095 15.682 7.10156 15.8707 7.10156 16.0664C7.10156 17.1326 7.96893 18 9.03516 18C10.1014 18 10.9688 17.1326 10.9688 16.0664C10.9688 15.8707 10.9392 15.682 10.885 15.5039H14.5095L16.7996 17.794C16.9369 17.9313 17.117 18 17.2969 18C17.4768 18 17.6567 17.9313 17.794 17.794C18.0687 17.5195 18.0687 17.0743 17.794 16.7996ZM9.03516 16.5938C8.74429 16.5938 8.50781 16.3571 8.50781 16.0664C8.50781 15.7755 8.74429 15.5391 9.03516 15.5391C9.32588 15.5391 9.5625 15.7755 9.5625 16.0664C9.5625 16.3571 9.32588 16.5938 9.03516 16.5938ZM10.3611 11.3555H4.95703V6.22266C4.95703 6.13394 4.96046 6.04674 4.96623 5.96063L10.3611 11.3555ZM4.95703 14.0977V12.7617H11.7673L13.1033 14.0977H4.95703ZM5.99826 2.91824C5.84528 2.56133 6.01062 2.14796 6.36768 1.99498C6.96588 1.73859 7.62465 1.57681 8.33203 1.51076V0.703125C8.33203 0.314758 8.64679 0 9.03516 0C9.42352 0 9.73828 0.314758 9.73828 0.703125V1.51021C12.6238 1.77745 14.5195 3.61285 14.5195 6.22266V10.0898C14.5195 10.4782 14.2048 10.793 13.8164 10.793C13.428 10.793 13.1133 10.4782 13.1133 10.0898V6.22266C13.1133 3.80223 11.0385 2.91069 9.09503 2.88322H8.97528C8.22409 2.89394 7.5141 3.03374 6.92139 3.28752C6.56447 3.44051 6.15111 3.27516 5.99826 2.91824Z"
                      fill="rgba(19, 181, 106, 1)"
                    />
                  </svg>
                </c-card>
                <div class="flex flex-col pl-4 w-full">
                  <span
                    class="text-xs font-semibold uppercase text-mediumSeaGreen"
                    >Total Taken</span
                  >
                  <span class="text-lg font-extrabold">0</span>
                </div>
              </div>
            </div>
          </c-card>
        </div>
        <template v-slot:footer>
          <CardFooter
            search-placeholder="Search by leave type or details"
            @actionModal="isOpenFilter = true"
            @sortType="$queryBuilder({ sort: $event }, getLeaveRequests)"
            @searchResult="$queryBuilder({ search: $event }, getLeaveRequests)"
          >
          </CardFooter>
        </template>
      </c-card>

      <c-table
        :headers="headers"
        :items="leaveRequests"
        aria-label="annual leave plan table"
        :loading="isFetching"
        :has-number="false"
        :page-sync="true"
        :pagination-list="pagination"
        @page="$queryBuilder({ page: $event }, getLeaveRequests)"
        @itemsPerPage="$queryBuilder({ perPage: $event }, getLeaveRequests)"
        v-if="isFetching || leaveRequests"
      >
        <template v-slot:item="{ item }">
          <span
            v-if="item.createdAt"
            class="text-darkPurple text-sm font-normal"
          >
            {{ $DATEFORMAT(new Date(item.data.createdAt), "MMMM dd, yyyy") }}
          </span>
          <span
            v-else-if="item.category"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.category.name }}
          </span>
          <div
            v-else-if="item.requestStartDate"
            class="flex flex-col justify-start gap-1"
          >
            <div class="text-darkPurple text-sm font-semibold">
              {{
                $DATEFORMAT(
                  new Date(item.data.requestStartDate),
                  "MMMM dd, yyyy"
                )
              }}
              <span class="text-carrotOrange">to</span><br />
              {{
                $DATEFORMAT(new Date(item.data.requestEndDate), "MMMM dd, yyyy")
              }}
            </div>
          </div>
          <span v-if="item.daysRequested" class="text-sm">
            {{ item.data.daysRequested }} Day(s)
          </span>
          <span
            v-if="item.leaveBalanceAtRequest"
            class="text-darkPurple text-sm font-normal"
          >
            {{ item.data.leaveBalanceAtRequest ? `${item.data.leaveBalanceAtRequest} days` : '---' }}
          </span>
          <div
            v-if="item.approvalStatus"
            class="rounded-md p-2 text-sm w-28 text-center capitalize"
            :class="{
              'bg-green-100 text-mediumSeaGreen':
                item.data.approvalStatus === 'approved',
              'bg-yellow-100 text-carrotOrange':
                item.data.approvalStatus === 'pending',
              'bg-red-100 text-flame':
                item.data.approvalStatus === 'disapproved',
              'bg-red-100 text-flame': item.data.approvalStatus === 'cancelled',
            }"
          >
            {{ item.data.approvalStatus }}
          </div>
        </template>
      </c-table>

      <div v-else class="flex flex-col justify-center items-center gap-0 px-10">
        <c-icon
          icon-name="paygrade_Illustration"
          size=""
          class-name="w-56 h-56"
        />
        <p class="font-normal text-base leading-5 text-jet text-center w-1/2">
          Leave requests can be found here. This is empty now, but you can start
          adding leave requests to populate this area.
        </p>
      </div>

      <FilterTable
        v-if="isOpenFilter"
        :filter-data="filterOptions"
        :show-search="false"
        @close="isOpenFilter = false"
        @applyFilter="onSubmitFilter($event)"
      />

      <c-modal v-if="isOpenDeleteModal">
        <c-card class="flex flex-col gap-5 max-w-max py-3 px-5">
          <p
            class="text-jet text-base font-bold border-b border-solid border-romanSilver"
          >
            Attention Required!
          </p>
          <div class="flex flex-col justify-start items-start gap-0">
            <p class="text-jet text-base">
              Are you sure you want to delete this request?
            </p>
            <p class="text-jet text-base">This cannot be undone.</p>
          </div>
          <div class="w-full flex justify-center gap-4">
            <c-button
              label="Cancel"
              variant="secondary c-button btn-border"
              :disabled="isDeletingRequest"
              @onClick="isOpenDeleteModal = false"
            />
            <c-button
              variant="primary btn-bg c-button"
              :disabled="isDeletingRequest"
              :label="isDeletingRequest ? 'Deleting...' : 'Delete'"
              @onClick="onCancelLeaveRequest(selectedItem)"
            />
          </div>
        </c-card>
      </c-modal>

      <AddLeaveRequest ref="createRequestRef" @submit="getLeaveRequests" />
      <ViewLeaveRequest ref="viewRequestRef" />
      <GetLeaveBackRequest ref="getLeaveBackRef" @success="getLeaveRequests" />
      <ApprovalStatus ref="approvalStatusRef" :mode="mode" />
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CButton from "@scelloo/cloudenly-ui/src/components/button";
import CTable from "@scelloo/cloudenly-ui/src/components/table";
import CCard from "@scelloo/cloudenly-ui/src/components/card";
import CMenu from "@scelloo/cloudenly-ui/src/components/Menu";
import CIcon from "@/components/Icon";
import CModal from "@/components/Modal";
import CardFooter from "@/components/CardFooter";
import FilterTable from "@/components/FilterTable";
import ApprovalStatus from "@/components/ApprovalStatus";

export default {
  components: {
    Breadcrumb,
    BackButton,
    CCard,
    CButton,
    CTable,
    CIcon,
    CMenu,
    CModal,
    CardFooter,
    FilterTable,
    ApprovalStatus,

    AddLeaveRequest: () => import("./sideActions/AddLeaveRequest"),
    ViewLeaveRequest: () => import("./sideActions/ViewLeaveRequest"),
    GetLeaveBackRequest: () => import("./sideActions/GetLeaveBack"),
  },
  data() {
    return {
      isOpenFilter: false,
      isFetching: false,
      isDeletingRequest: false,
      isOpenDeleteModal: false,
      setQueryTimeout: null,
      queryParams: {},
      selectedItem: {},
      leaveRequests: [],
      employeeInfo: {},
      pendingRequests: [],
      approvedRequests: [],
      disapprovedRequests: [],
      cancelledRequests: [],
      pagination: {},
      headers: [
        { title: "Request Date", value: "createdAt" },
        { title: "Leave Type", value: "category" },
        { title: "Duration", value: "requestStartDate" },
        { title: "Total days taken (to date)", value: "daysRequested" },
        { title: "Leave Balance", value: "leaveBalanceAtRequest" },
        { title: "status", value: "approvalStatus" },
      ],
      filterOptions: [
        {
          header: "Status",
          value: "status",
          optionsBox: [
            { name: "Pending", id: "pending" },
            { name: "Approved", id: "approved" },
            { name: "Disapproved", id: "disapproved" },
            { name: "Cancelled", id: "cancelled" },
          ],
        },
      ],
    };
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  computed: {
    breadcrumbs() {
      return [
        { disabled: false, text: "Leave", href: "Leave", id: "Leave" },
        {
          disabled: false,
          text: "Year End Leave Balances",
          id: "Year End Leave Balances",
        },
        {
          disabled: false,
          text: `View Leave History`,
          id: `View Leave History`,
        },
      ];
    },
  },
  methods: {
    onSubmitFilter(options) {
      this.$queryBuilder(options, this.getLeaveRequests);
      this.isOpenFilter = false;
    },
    onCancelLeaveRequest(request) {
      this.isDeletingRequest = true;
      this.$_cancelLeaveRequest(request.id)
        .then(({ data }) => {
          this.$toasted.success(data.message, { duration: 3000 });
          this.getLeaveRequests();
          this.isDeletingRequest = false;
          this.isOpenDeleteModal = false;
        })
        .catch((error) => {
          this.$toasted.error(error.message, { duration: 3000 });
          this.getLeaveRequests();
          this.isDeletingRequest = false;
        });
    },
    async getLeaveRequests(params) {
      this.isFetching = true;

      const payload = { employeeId: this.$route.params.id, params };
      await this.$_getEmployeeLeaveRequest(payload)
        .then(({ data }) => {
          this.leaveRequests = data.requests;
          this.employeeInfo = data.employee;
          this.pendingRequests = data.requests?.filter(
            (v) => v?.approvalStatus === "pending"
          );
          this.approvedRequests = data.requests?.filter(
            (v) => v?.approvalStatus === "approved"
          );
          this.disapprovedRequests = data.requests?.filter(
            (v) => v?.approvalStatus === "disapproved"
          );
          this.cancelledRequests = data.requests?.filter(
            (v) => v?.approvalStatus === "cancelled"
          );
          this.pagination = data.meta;

          this.isFetching = false;
        })
        .catch(() => {
          // const message = "Something went wrong, try again!"
          // this.$toasted.error(error || message, { duration: 5000 })
          this.$toasted.error(
            "Error fetching data, please check your network.",
            { duration: 3000 }
          );
        });
    },
    async bootstrapModule() {
      this.isLoading = true;
      await this.getLeaveRequests("");
      this.isLoading = false;
    },
  },
  created() {
    this.bootstrapModule();
  },
};
</script>

<style scoped>
::v-deep .select-class span select {
  margin-top: 3px !important;
  height: 42px !important;
}
.button-style {
  font-weight: 600 !important;
  font-size: 16px !important;
  font-size: 16px !important;
  line-height: 120% !important;
  padding: 10px 50px !important;
}

.btn-bg {
  background-color: var(--dynamic-Background) !important;
}

.btn-border {
  color: var(--dynamic-Background) !important;
  border: 1px solid var(--dynamic-Background) !important;
}
</style>
